.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #1a1a1a; /* Modal background */
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .modal h2 {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #3c3c3c;
    background-color: #2a2a2a;
    color: #f5f5f5;
  }
  
  textarea {
    resize: none;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .create-button {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 48%; /* Ensures both buttons are evenly spaced */
  }
  
  .create-button:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  .cancel-button {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 48%; /* Ensures both buttons are evenly spaced */
  }
  
  .cancel-button:hover {
    background-color: #c82333;
    transform: scale(1.05);
  }
  