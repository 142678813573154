.container {
  padding: 20px;
  background-color: #121212; /* Dark Gray Background */
  border-radius: 8px;
  margin: 20px;
  color: #ffffff; /* White Text */
}

.room-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #ffd600; /* Accent Gold */
}

.column-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.column {
  background-color: #333333; /* Darker Gray for Columns */
  padding: 16px;
  border-radius: 8px;
  min-width: 200px;
  flex: 1;
  color: #ffffff; /* White Text */
}

.feedback-item {
  background-color: #1e1e1e; /* Slightly lighter Dark Gray */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #ffffff; /* White Text */
}

.feedback-meta {
  display: flex;
  gap: 10px;
  color: #ffd600; /* Accent Gold for meta information */
}

.view-only-message {
  color: #e91e63; /* Secondary Button Magenta */
  font-style: italic;
  margin-top: 20px;
}
