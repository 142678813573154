/* Global Styles */
:root {
  --bg-color: #121212; /* Dark Gray */
  --text-color: #ffffff; /* White */
  --primary-color: #00bcd4; /* Bright Cyan */
  --secondary-color: #e91e63; /* Magenta */
  --accent-color: #ffd600; /* Gold */
}

.App {
  text-align: center;
  background-color: var(--bg-color); /* Dark Gray Background */
  color: var(--text-color); /* White Text */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--bg-color); /* Dark Gray Background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color); /* White Text */
}

.App-link {
  color: var(--primary-color); /* Bright Cyan for Primary Links */
  transition: color 0.3s;
}

.App-link:hover {
  color: var(--secondary-color); /* Magenta on hover */
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-color); /* Magenta on hover */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
