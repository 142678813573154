/* General Container */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: Arial, sans-serif;
  transition: background-color 0.5s ease;
}

/* Time Toggle Button */
.time-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Weather Particles */
.raindrop {
  position: absolute;
  background: rgba(173, 216, 230, 0.7);
  animation: rainfall 4s linear infinite;
}

.snowflake {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: snowfall 5s linear infinite;
}

/* Title and Message */
.not-found-title {
  font-size: 6rem;
  color: white;
}

.not-found-message {
  font-size: 1.5rem;
  color: white;
  margin: 20px 0;
}

/* Weather Controls */
.weather-controls {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.weather-button {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.weather-button.active {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.2);
}

/* Return Button */
.not-found-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s ease;
}

.not-found-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Animations */
@keyframes rainfall {
  0% {
    transform: translateY(-10vh) rotate(15deg);
  }
  100% {
    transform: translateY(100vh) rotate(15deg);
  }
}

@keyframes snowfall {
  0% {
    transform: translateY(-10vh) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
