/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #121212; /* Dark Gray */
  color: #ffffff; /* White Text */
}

.App {
  display: flex;
  min-height: 100vh;
}

button {
  cursor: pointer;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #00bcd4; /* Bright Cyan for Primary Buttons */
  color: #ffffff; /* White Text */
}

/* Sidebar Styles */
aside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 16rem; /* 64px * 4 = 256px */
  height: 100vh;
  background-color: #1f2937; /* Dark Gray */
  transition: transform 0.3s ease;
}

aside.bg-gray-50 {
  background-color: #1f2937; /* Dark Gray */
}

aside ul {
  list-style-type: none;
  padding: 0;
}

aside ul li {
  margin-top: 1rem;
}

aside ul li a {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #ffffff; /* White Text */
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

aside ul li a:hover {
  background-color: #00bcd4; /* Bright Cyan for hover effect */
}

aside ul li span {
  margin-left: 1rem;
}

/* Sidebar Toggle Button */
.inline-flex {
  cursor: pointer;
}

.inline-flex svg {
  width: 1.5rem;
  height: 1.5rem;
}

.sm\\:hidden {
  display: none;
}

@media (max-width: 640px) {
  .sm\\:hidden {
    display: inline-flex;
  }

  aside {
    transform: translateX(-100%);
  }

  .translate-x-0 {
    transform: translateX(0);
  }

  .translate-x-64 {
    transform: translateX(16rem);
  }
}

/* Main Content */
.p-4 {
  padding: 1rem;
}

.sm\\:ml-64 {
  margin-left: 16rem;
}

.border-dashed {
  border-style: dashed;
}

.rounded-lg {
  border-radius: 0.5rem;
}

/* Dark Mode and Accent Colors */
.text-gray-400 {
  color: #ffd600; /* Gold for Accents */
}

.text-gray-500 {
  color: #e91e63; /* Magenta for Secondary Text */
}

.text-gray-900 {
  color: #ffffff; /* White Text */
}

.bg-gray-100 {
  background-color: #121212; /* Dark Gray Background */
}

.bg-gray-50 {
  background-color: #121212; /* Dark Gray Background */
}

.dark\\:text-white {
  color: #ffffff; /* White Text */
}

.dark\\:bg-gray-700 {
  background-color: #1f2937; /* Dark Gray */
}

.dark\\:border-gray-700 {
  border-color: #1f2937; /* Dark Gray */
}

.dark\\:hover\\:bg-gray-700:hover {
  background-color: #00bcd4; /* Bright Cyan for hover */
}

.dark\\:focus\\:ring-gray-600:focus {
  outline-color: #4b5563; /* Ring color */
}
