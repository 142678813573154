.countdown-timer {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #121212; /* Dark Gray Background */
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffffff; /* White Text */
}

.countdown-input {
  margin-bottom: 20px;
}

.countdown-input input {
  width: 50px;
  margin: 5px;
  padding: 5px;
  text-align: center;
  background-color: #121212; /* Dark Gray Background */
  color: #ffffff; /* White Text */
  border: 1px solid #ffd600; /* Accent Gold */
}

.countdown-input button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #00bcd4; /* Primary Button Bright Cyan */
  color: #ffffff; /* White Text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.countdown-input button:hover {
  background-color: #e91e63; /* Secondary Button Magenta on hover */
}

.countdown-display {
  display: flex;
  justify-content: space-around;
}

.bloc-time {
  text-align: center;
}

.count-title {
  font-size: 0.8em;
  margin-bottom: 5px;
  color: #ffd600; /* Accent Gold */
}

.figure {
  font-size: 2em;
  color: #ffd600; /* Accent Gold */
}
