.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #121212; /* Dark Gray Background */
  color: #ffffff; /* White Text */
}

.room-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffd600; /* Gold Accent */
}

.shareable-link {
  margin-bottom: 20px;
}

.shareable-link input {
  width: calc(100% - 80px);
  padding: 8px;
  margin-right: 10px;
  background-color: #333333; /* Darker Background for Inputs */
  color: #ffffff;
  border: 1px solid #00bcd4; /* Cyan Border */
}

.shareable-link button {
  padding: 8px 12px;
  cursor: pointer;
  background-color: #00bcd4; /* Bright Cyan for Primary Buttons */
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.column-container {
  display: flex;
  gap: 20px;
}

.column {
  flex: 1;
  background-color: #333333; /* Darker Gray for Columns */
  padding: 10px;
  border-radius: 8px;
  color: #ffffff; /* White Text */
}

.review-section {
  margin-bottom: 10px;
}

.feedback-item {
  background: #1e1e1e; /* Slightly lighter Dark Gray */
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ffd600; /* Gold Border */
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: popIn 0.3s ease-in-out; /* Pop-in animation */
}

/* Pop-in animation for feedback items */
@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.feedback-actions {
  display: flex;
  gap: 10px;
}

.feedback-actions button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #e91e63; /* Magenta for Secondary Button Text */
}

.feedback-input textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #333333; /* Darker Background */
  color: #ffffff; /* White Text */
}

.submit-button-container {
  text-align: center;
  margin-top: 20px;
}

.submit-button-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #00bcd4; /* Bright Cyan for Primary Buttons */
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

/* Kudos Section */
.kudos-section {
  margin-top: 30px;
  padding: 15px;
  background-color: #333333; /* Darker Background */
  border-radius: 8px;
}

.kudos-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffd600; /* Gold Accent */
}

.kudos-list {
  list-style: none;
  padding: 0;
}

.kudos-item {
  background: #1e1e1e;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ffd600; /* Gold Border */
  border-radius: 5px;
  color: #ffffff;
}

/* Reaction Button */
.reaction-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  margin-left: 5px;
  color: #00bcd4; /* Cyan for Reactions */
}

.reaction-button:hover {
  transform: scale(1.2);
}

.reaction-counter {
  font-size: 14px;
  margin-left: 3px;
  color: #ffd600; /* Gold Accent for Counter */
}
