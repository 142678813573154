.custom-checklist {
    background-color: #2a2a2a;
    color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
  }
  
  .checklist-form {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .checklist-form input {
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .checklist-form button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .checklist-form button:hover {
    background-color: #218838;
  }
  
  .checklist-items {
    list-style: none;
    padding: 0;
  }
  
  .checklist-items li {
    margin-bottom: 10px;
  }
  
  .checklist-items li.completed label {
    text-decoration: line-through;
    color: #888;
  }
  
  .checklist-items input[type='checkbox'] {
    margin-right: 10px;
  }
  