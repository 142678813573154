.timeline-view {
    background-color: #2a2a2a;
    color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
  }
  
  h2 {
    color: #ffc107;
  }
  
  .filter-controls {
    margin-bottom: 20px;
  }
  
  .filter-controls select {
    padding: 5px;
    border-radius: 5px;
    border: none;
    background-color: #444;
    color: #fff;
  }
  
  .timeline-list {
    list-style: none;
    padding: 0;
  }
  
  .timeline-event {
    background-color: #333;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    position: relative;
  }
  
  .timeline-event.milestone {
    border-left: 5px solid #0ffcbe;
  }
  
  .timeline-event.deadline {
    border-left: 5px solid #ff9800;
  }
  
  .timeline-event.task {
    border-left: 5px solid #8bc34a;
  }
  
  .event-type {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 0.85rem;
    background-color: #555;
    padding: 3px 5px;
    border-radius: 3px;
    color: #fff;
  }
  