.post-release-metrics {
    margin-top: 20px;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 8px;
  }
  
  .post-release-metrics h3 {
    color: #0ffcbe;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .metrics-error {
    color: #ff5722;
    text-align: center;
    padding: 20px;
    background-color: #3c3c3c;
    border-radius: 8px;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #0ffcbe;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 50px auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  