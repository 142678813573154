.room-dashboard {
    background-color: #1a1a1a;
    color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
  }
  
  .dashboard-content {
    margin-bottom: 20px;
  }
  
  .dashboard-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 8px;
  }
  
  .dashboard-section h2 {
    color: #0ffcbe;
    margin-bottom: 10px;
  }
  
  .dashboard-section:last-child {
    margin-bottom: 0;
  }
  
  .snapshot-button, .offline-view-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #0ffcbe; /* Teal for snapshot */
    color: #1a1a1a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .snapshot-button:hover, .offline-view-button:hover {
    background-color: #00bcd4;
  }
  
  .offline-view-button {
    background-color: #ff9800; /* Orange for offline view */
    margin-top: 10px;
  }
  
  .offline-view-button:hover {
    background-color: #ff5722; /* Darker orange for hover */
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #0ffcbe;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .timeline-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 8px;
  }
  
  .timeline-section h2 {
    color: #ffcc00;
    margin-bottom: 10px;
  }
  
  .custom-checklist {
    margin-top: 20px;
    padding: 15px;
    background-color: #333;
    border-radius: 8px;
  }
  
  .custom-checklist h3 {
    color: #ff4081;
  }
  
  .custom-checklist input[type='checkbox'] {
    margin-right: 10px;
  }
  
  /* Email Button */
  .email-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .email-button:hover {
    background-color: #45a049;
  }
  