/* ScenarioAnalysis.css */

.scenario-analysis {
    padding: 20px;
    background-color: #2a2a2a; /* Dark background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #f5f5f5; /* Light text for readability */
    margin-top: 20px;
  }
  
  .scenario-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .scenario-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .add-scenario-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .add-scenario-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .scenario-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between scenarios */
  }
  
  .scenario-item {
    padding: 15px;
    border: 1px solid #3c3c3c;
    border-radius: 8px;
    background-color: #3b3b3b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .scenario-item:hover {
    background-color: #444;
  }
  
  .scenario-info {
    display: flex;
    flex-direction: column;
  }
  
  .scenario-info p {
    margin: 5px 0;
  }
  
  .scenario-actions button {
    background-color: #dc3545; /* Red button for remove */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .scenario-actions button:hover {
    background-color: #c82333;
  }
  