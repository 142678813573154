.release-readiness-tracker-page {
  padding: 20px;
  padding-top: 80px; /* Adjust top padding to prevent overlap */
  background-color: #1a1a1a; /* Darker background for contrast */
  color: #f5f5f5; /* Lighter text for readability */
  position: relative; /* Ensure child elements respect this context */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.create-room-button {
  position: absolute; /* Position the button relative to the page */
  top: 20px; /* Align below the top navigation */
  right: 20px; /* Align near the right edge of the screen */
  background-color: #28a745; /* Green button */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 10; /* Ensure it appears above other content */
}

.create-room-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

.room-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between room items */
}

.room-item {
  padding: 15px;
  border: 1px solid #3c3c3c;
  border-radius: 8px;
  background-color: #2a2a2a; /* Darker room background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
}

.room-item.active {
  background-color: #444; /* Highlight active room */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.room-item:hover {
  background-color: #3c3c3c;
}

.room-name {
  font-size: 1.1rem;
  font-weight: 600;
}

.room-date {
  font-size: 0.9rem;
  color: #a5a5a5; /* Muted color for date */
}

.room-status {
  font-size: 0.9rem;
  font-weight: bold;
}

.room-status.ready {
  color: #28a745; /* Green for ready */
}

.room-status.not-ready {
  color: #dc3545; /* Red for not ready */
}

.room-status.pending {
  color: #ffc107; /* Yellow for pending */
}
