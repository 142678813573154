.risk-assessment {
    background-color: #2a2a2a;
    color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filters label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filters select {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: #3c3c3c;
    color: white;
}

.add-risk {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.add-risk input,
.add-risk select {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: #3c3c3c;
    color: white;
    width: 100%;
}

.add-risk button {
    padding: 10px 15px;
    background-color: #0ffcbe;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-risk button:hover {
    background-color: #00bcd4;
}

.risk-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.risk-item {
    background-color: #333;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    border-left: 5px solid transparent; /* Default border for priority */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.risk-item.high {
    border-left: 5px solid #ff5722; /* High priority */
}

.risk-item.medium {
    border-left: 5px solid #ff9800; /* Medium priority */
}

.risk-item.low {
    border-left: 5px solid #8bc34a; /* Low priority */
}

.risk-item:hover {
    background-color: #3c3c3c;
    transform: translateY(-2px); /* Slight hover effect */
}

.risk-item select {
    padding: 5px;
    border-radius: 5px;
    border: none;
    background: #444;
    color: white;
    margin-top: 5px;
    width: 100%;
}

.risk-item p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #c7c7c7;
}
